import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Subject, Observable, Subscription, of } from "rxjs";
import { debounceTime, switchMap, tap } from "rxjs/operators";

import {
  SharedModule,
  FormInputComponent,
  DataService,
  InputComponentConfig,
} from "@vendure/admin-ui/core";
import { UntypedFormControl } from "@angular/forms";
import gql from "graphql-tag";

// Define FacetFragment according to your schema or types
type FacetFragment = {
  id: string;
  name: string;
  code: string;
};

@Component({
  templateUrl: "./facet-select.component.html",
  styleUrls: ["./facet-select.component.scss"],
  standalone: true,
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacetSelectComponent
  implements FormInputComponent, OnInit, OnDestroy
{
  static readonly id = "facet-select-form-input";
  readonly isListInput = true;
  @Input() readonly = false;
  searchInput$ = new Subject<string>();
  searchLoading = false;
  searchResults$: Observable<FacetFragment[]>;
  selectedIds$ = new Subject<string[]>();
  formControl = new UntypedFormControl();
  config: InputComponentConfig;
  value: Array<string | FacetFragment> = [];

  private subscription = new Subscription();

  constructor(
    private dataService: DataService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  valueTransformFn = (values: FacetFragment[]) => {
    const isUsedInConfigArg = this.config?.__typename === "ConfigArgDefinition";
    if (isUsedInConfigArg) {
      return values.map((v) => v.id);
    } else {
      return values;
    }
  };

  ngOnInit(): void {
    this.initFacetsOptions();
  }

  initFacetsOptions() {
    this.searchResults$ = this.dataService
      .query(
        gql`
          query {
            facets {
              items {
                id
                name
                code
              }
            }
          }
        `
      )
      .mapSingle((data: any) => data.facets.items)
      .pipe(
        tap((items) => {
          this.searchLoading = false;
          this.searchResults$ = of(items);
        })
      );

    this.subscription.add(
      this.searchInput$
        .pipe(
          debounceTime(300),
          switchMap((searchTerm: string) => {
            // If search term is empty or undefined, return all facets
            if (!searchTerm || searchTerm.trim() === "") {
              return this.dataService
                .query(
                  gql`
                    query {
                      facets {
                        items {
                          id
                          name
                          code
                        }
                      }
                    }
                  `
                )
                .mapSingle((data: any) => data.facets.items);
            }

            // Otherwise, perform the search with the term
            return this.dataService
              .query(
                gql`
                  query ($term: String!) {
                    facets(options: { filter: { name: { contains: $term } } }) {
                      items {
                        id
                        name
                        code
                      }
                    }
                  }
                `,
                { variables: { term: searchTerm.trim() } }
              )
              .mapSingle((data: any) => data.facets.items);
          })
        )
        .subscribe((results) => {
          this.searchResults$ = of(results);
          this.changeDetectorRef.markForCheck();
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  registerOnChange(fn: any): void {
    this.formControl.valueChanges.subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  }

  onChange(event: FacetFragment[]) {
    if (!event) {
      this.formControl.setValue([]);
      return;
    }

    const transformed = this.valueTransformFn(event);
    this.formControl.setValue(transformed);
    this.changeDetectorRef.markForCheck();
  }
}
