import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { SharedModule, TextCustomFieldConfig } from "@vendure/admin-ui/core";

@Component({
  templateUrl: "./comment-field.component.html",
  styleUrl: "./comment-field.component.scss",
  standalone: true,
  imports: [SharedModule],
})
export class CommentFieldComponent {
  readonly: boolean;
  config: TextCustomFieldConfig;
  formControl: FormControl;
}
