<ng-select
  [items]="searchResults$ | async"
  [multiple]="true"
  [hideSelected]="true"
  [loading]="searchLoading"
  [bindLabel]="'name'"
  [bindValue]="'id'"
  [clearable]="true"
  [formControl]="formControl"
  (change)="onChange($event)"
  (search)="searchInput$.next($event.term)"
  [placeholder]="'Select facets' | translate"
>
  <ng-template ng-option-tmp let-item="item">
    <div class="option-container">
      <span [ngOptionHighlight]="item.name">{{ item.name }}</span>
      <span class="option-code">({{ item.code }})</span>
    </div>
  </ng-template>
</ng-select>
