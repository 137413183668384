import { registerFormInputComponent } from "@vendure/admin-ui/core";
import { ColorPickerInputComponent } from "./components/color-picker-input/color-picker-input.component";
import { CommentFieldComponent } from "./components/comment-field/comment-field.component";
import { FacetSelectComponent } from "./components/facet-select/facet-select.component";

export default [
  registerFormInputComponent("color-picker-input", ColorPickerInputComponent),
  registerFormInputComponent("comment-field", CommentFieldComponent),
  registerFormInputComponent("facet-select", FacetSelectComponent),
];
